import { Box } from "@mui/material";
import locationGPS from "../assets/locationGps.png";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import { useUserContext } from "../context/user-context";
import toast from "react-hot-toast";

function LocationGPS() {
  const navigate = useNavigate();
  const { setUserData } = useUserContext();

  const handleGeolocationSuccess = (position: GeolocationPosition) => {
    setUserData((prev) => ({
      ...prev,
      latitude: position.coords.latitude.toString(),  // Convert to string
      longitude: position.coords.longitude.toString(), // Convert to string
    }));
    navigate("/distance-preference");
  };

  const handleGeolocationError = (error: GeolocationPositionError) => {
    toast.error(`Error getting location: ${error.message}`);
  };

  const requestGeolocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(handleGeolocationSuccess, handleGeolocationError);
    } else {
      toast.error("Geolocation is not available in your browser.");
    }
  };

  useEffect(() => {
    requestGeolocation();
  }, []);

  return (
    <Grid container>
      <Grid  size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid  size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
        <Box className="login-section">
          <LogoWhiteDude />
          </Box>
         
          <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>
            <h2 className={styles.phone_texthead} style={{marginBottom:"3%"}}>Where are you from?</h2>
            {/* <div>
              {error ? (
                <p>{error}</p>
              ) : (
                <>
                  <p><b>Latitude:</b> {latitude} </p>
                  <p><b>Longitude:</b> {longitude}</p>
                </>
              )}
            </div> */}
            <img
              src={locationGPS}
              alt="location-gps"
              style={{ height: "200px" }}
            />

            <Box
              className="next_button"
              sx={{ marginTop: "18px", width: "60%" }}
              // onClick={() => navigate("/distance-preference")}
              onClick={requestGeolocation}
            >
              ALLOW
            </Box>

            <Grid  container spacing={2} sx={{ width: '100%' }}>
                  <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                    <button className="back_button" type="submit">
                      DENY
                    </button>
                  </Grid>
                  <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                    <Box className="deny_button" onClick={() => navigate(-1)}>
                      BACK
                    </Box>
                  </Grid>
                </Grid>
        </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LocationGPS;

import React, { useState, ChangeEvent } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PostMethod } from "../api/fetch";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useUserContext } from "../context/user-context";
import HomepageCard from "../components/HomepageCard";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import register_icon from "../assets/tick_icon.svg";
import styles from "./moduleCss/continuephonepage.module.css";
import Grid from "@mui/material/Grid2"

interface ImageObject {
  file: File;
  dataURL: string;
}

function UploadImagePic() {
  const navigate = useNavigate();
  const { userData } = useUserContext();

  const [imageData, setImageData] = useState<ImageObject[]>([]);
  const [open, setOpen] = useState(false);
  const [otpError, setOtpError] = useState("");

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files) as File[];
      const imagePromises = selectedImages.map((image) =>
        new Promise<ImageObject>((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve({ file: image, dataURL: event.target?.result as string });
          };
          reader.onerror = reject;
          reader.readAsDataURL(image);
        })
      );

      Promise.all(imagePromises)
        .then((imageDataArray) => {
          setImageData((prev) => [...prev, ...imageDataArray]);
        })
        .catch((error) => {
          console.error("Error uploading images: ", error);
        });
    }
  };

  const handleAPIUserInfo = () => {
    setOtpError("");
    const formData = new FormData();

    formData.append("email", userData.email);
    formData.append("full_name", userData.full_name);
    formData.append("dob", userData.dob);
    formData.append("gender", userData.gender);
    formData.append("sexual_orientation", userData.sex_orientation);
    formData.append("intrested_in", userData.sexual_interest);
    formData.append("relationship_status", userData.relationship_status);
    formData.append("looking_for", userData.looking_for);
    formData.append("state_id", userData.state_id.toString());
    formData.append("city_id", userData.city_id.toString());
    formData.append("distance", userData.distance.toString());
    formData.append("latitude", userData.latitude);
    formData.append("longitude", userData.longitude);
    formData.append("hobby", userData.your_hobby);
    formData.append("fcm_token", userData.fcm_token);
  
    imageData.forEach((imageObj) => {
      formData.append("images", imageObj.file);
    });

    console.log("formData", formData, userData)

    PostMethod("user/saveAdditionalInfo", formData)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            setOtpError("Please select at least 1 image");
          } else if (err.response.status === 400) {
            setOtpError("OTP is not allowed to be empty");
          }
        }
      });
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section" sx={{ mt: { xs: 3, md: 4 } }}>
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>

          <Box className="common_card_container input-box" sx={{ mt: { xs: 3, md: 4 } }}>
            <h2 className={styles.phone_texthead}>Upload Image</h2>
            <Box sx={{ display: "block", flexWrap: "wrap", width: "100%" }}>
              <div className="image-upload" style={{ textAlign: "center", marginBlock: "15px" }}>
                <label htmlFor="file-input">
                  <span>+</span>
                </label>
                <input id="file-input" type="file" multiple onChange={handleImageChange} />
              </div>
            </Box>
            <div className="uploaded-img">
              {imageData.map((imageObj, index) => (
                <img
                  key={index}
                  src={imageObj.dataURL}
                  alt={`preview-${index}`}
                  style={{
                    maxWidth: "100%",
                    height: "100%",
                    maxHeight: "110px",
                    minHeight: "110px",
                    objectFit: "cover",
                  }}
                />
              ))}
            </div>
            {otpError && (
              <p style={{ color: "red", fontSize: "14px", margin: "10px", fontWeight: 500 }}>
                {otpError}
              </p>
            )}
            <Grid container spacing={2} sx={{ width: "100%", marginBottom: "10px" }}>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box className="next_button" onClick={handleAPIUserInfo}>
                  SUBMIT
                </Box>
              </Grid>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box className="deny_button" sx={{ width: "100%" }} onClick={() => navigate(-1)}>
                  BACK
                </Box>
              </Grid>
            </Grid>

            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <img src={register_icon} alt="successfully done" />
                <Box className="success_text">You have Registered Successfully Enjoy!</Box>
                <Box className="next_button" sx={{ width: "60%" }} onClick={() => navigate("/")}>
                  CONTINUE
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UploadImagePic;

import { Box } from "@mui/material";
import { useState } from "react";
import white_dude_banner from "../assets/white_dude_logo.svg";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/verifyOtp.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import { PostMethod } from "../api/fetch";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import Grid from "@mui/material/Grid2";
import toast from "react-hot-toast";
import { useUserContext } from "../context/user-context";

function VerifyOtpNo() {
  const [otp, setOtp] = useState("");
  const [OtpError, setOtpError] = useState("");
  const navigate = useNavigate();
  const { userToken, setUserData } = useUserContext();

  const handletoVerificationOTP = () => {
    setOtpError("");
    const params = {
      otp: otp,
      fcm_token: userToken,
    };

    PostMethod("otpVerify", params)
      .then((res: any) => {
        console.log("response", res);
        if (res.status === 200) {
          if (userToken && !res.data.data.is_profile_completed) {
            toast.success("OTP verify successfully");
            navigate("/basic-details");
          } else {
            navigate("/")
            toast.success("User Already Regesterd.")
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 401) {
          toast.error("OTP not matched");
        }
        if (err.response.status === 400) {
          setOtpError("OTP is not allowed to be Empty");
        }
      });
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>
          <Box className="common_card_container" sx={{ mt: { xs: 3, md: 5 } }}>
            <h2 className={styles.phone_texthead}>Verification Code</h2>

            <Box
              className={styles.continuePhone_otpBox}
              sx={{ mt: { xs: 1, md: 2 } }}
            >
              <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={
                  <span style={{ visibility: "hidden", padding: "5px" }}>
                    -
                  </span>
                }
                renderInput={(props) => <input {...props} />}
              />

              <p
                style={{
                  color: "red",
                  fontSize: "14px",
                  margin: "10px",
                  fontWeight: "500",
                }}
              >
                {OtpError}
              </p>

              <h5 className={styles.verification_code_Text}>
                Enter verification code sent to your mobile number +91 79788xxxx
              </h5>

              <Box
                className={styles.next_button}
                // onClick={() => navigate("/basic-details")} k
                onClick={handletoVerificationOTP}
              >
                NEXT
              </Box>

              <h5 className={styles.bottom_resend_div}>
                <span className={styles.dont_recieve_code}>
                  Don’t received code?
                </span>
                <p className={styles.resend_mob_number}>
                  Resend Change Mobile Number
                </p>
              </h5>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default VerifyOtpNo;

import React from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Home_slider from "../assets/home_slider.png"
import home_slider_one from "../assets/home_slider_one.jpg"
import home_slider_two from "../assets/home_slider_two.jpg"

function HomeSlider() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  }; 
  return (
    <div className='main-slider'>
      <Slider {...settings}>
        <div className='slider-item'>
            <img src={Home_slider} alt="xyz" className="slider_img" />
        </div>
        <div className='slider-item'>
        <img src={home_slider_one} alt="xyz" className="slider_img"/>
        </div>
        <div className='slider-item'>
        <img src={home_slider_two} alt="xyz" className="slider_img"/>
        </div>
      </Slider>
    </div>
  );
}

export default HomeSlider;

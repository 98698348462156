import React from "react";
import { Box, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import { useUserContext } from "../context/user-context";
import HomepageCard from "../components/HomepageCard";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import styles from "./moduleCss/continuephonepage.module.css";
import useUserDataCheck from "../hooks/check-user-data";


function UserBasicDetails() {
  const navigate = useNavigate();
  const { setUserData } = useUserContext();

  const handleUserDetails = async (values: any) => {
    // Update the userData in the context
    setUserData((prevData) => ({
      ...prevData,
      full_name: values.fullName.trim(),
      email: values.email.trim(),
      gender: values.gender,
      dob: values.DOB,
    }));
    navigate("/your-preference");
  };

  useUserDataCheck(["full_name", "email", "gender", "dob"], "/your-preference");

  const initialValues = {
    fullName: "",
    email: "",
    gender: "",
    DOB: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email Required")
      .email("Invalid email format"),
    fullName: Yup.string()
      .required("Please enter a Full Name")
      .min(3, "Full Name is too short"),
    DOB: Yup.string().required("Please enter your Date Of Birth"),
    gender: Yup.string().required("Please Select Your Gender"),
  });

  return (
    <Grid container>
      <Grid item xs={12} sm={6} md={6} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid item xs={12} sm={6} md={6} className="login-form">
        <Box className="container_section">
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>
          <Box className="common_card_container" sx={{ mt: { xs: 3, md: 5 } }}>
            <h2 className={styles.phone_texthead}>Your Basic Details?</h2>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleUserDetails}
            >
              <Form style={{ width: "100%" }} className="input-box">
                <Field
                  as={OutlinedInput}
                  placeholder="Enter Your Email Address"
                  name="email"
                  sx={{
                    width: "100%",
                    mt: 2,
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #cacaca",
                      },
                    },
                  }}
                />
                <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                  <ErrorMessage name="email" />
                </p>
                <Field
                  as={OutlinedInput}
                  placeholder="Enter Your Full Name"
                  name="fullName"
                  sx={{
                    width: "100%",
                    mt: 2,
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #cacaca",
                      },
                    },
                  }}
                />
                <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                  <ErrorMessage name="fullName" />
                </p>
                <Field
                  as={OutlinedInput}
                  type="date"
                  name="DOB"
                  sx={{
                    width: "100%",
                    mt: 2,
                    "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "1px solid #cacaca",
                      },
                    },
                  }}
                />
                <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                  <ErrorMessage name="DOB" />
                </p>
                <Field
                  as="select"
                  name="gender"
                  className="custom-select-dropdown"
                >
                  <option value="">Select Your Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Field>
                <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                  <ErrorMessage name="gender" />
                </p>
                <button type="submit" className="next_button">
                  NEXT
                </button>
              </Form>
            </Formik>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default UserBasicDetails;

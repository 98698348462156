import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import fb_icon from "../assets/fbIcon.svg";
import instagram_icon from "../assets/instagram_icon.svg";
import twitter_icon from "../assets/twitterIcon.svg";
import google_icon from "../assets/google.svg";
import phone_icon from "../assets/phoneIcon.svg";
import HomepageCard from "../components/HomepageCard";
import { useNavigate } from "react-router-dom";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import {
  IResolveParams,
  LoginSocialFacebook,
  LoginSocialGoogle,
} from "reactjs-social-login";
import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { PostMethod } from "../api/fetch";
import { useUserContext } from "../context/user-context";

function Login() {
  const navigate = useNavigate();
  const { userToken, setUserToken } = useUserContext();
  const [isLoading, setLoading] = useState(true);

  const SocialLoginAPI = async (login: any, id: any, email: any) => {
    setLoading(true);
    const payload = { login_with: login, social_id: id, email };
    const response: any = await PostMethod("socialLogin", payload)
      .then((res) => res)
      .catch((err) => {
        console.log("============", err);
        toast.error(err?.response?.data?.message);
      })
      .finally(() => setLoading(false));
    console.log("result==>", JSON.stringify(response));

    if (response?.data?.statusCode === 200) {
      setUserToken(response?.data?.data?.token);
      if (response?.data?.data?.is_profile_completed) {
        navigate("/");
      } else {
        navigate("/basic-details");
      }
    }
  };

  return (
    <Grid container className="login-container">
      <Grid size={{ xs: 12, sm: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section_login">
          <LogoWhiteDude />
          <Box className="social-media-container" sx={{ mt: { xs: 3, md: 5 } }}>
            <LoginSocialFacebook
              // appId={process.env.APP_ID || ""}
              // fieldsProfile={
              //   "id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender"
              // }
              // onLoginStart={onLoginStart}
              // onLogoutSuccess={onLogoutSuccess}
              // redirect_uri={"http://localhost:3000/register"}
              // onResolve={({ provider, data }: IResolveParams) => {
              //   console.log("provider", provider);
              //   console.log("data", data);
              // }}
              // onReject={(err) => {
              //   console.log(err);
              // }}
              fieldsProfile={"id,email,name"}
              appId={process.env.REACT_APP_FB_APP_ID || ""}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log("provider:", provider, "data:", data);
                SocialLoginAPI("facebook", data?.id, data?.email);
              }}
              onReject={(err) => {
                console.log(err);
              }}
              className="socialmedia-box"
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs: 2, sm: 2, md: 2 }}>
                  <img src={fb_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs: 10, sm: 10, md: 10 }}>
                  <h5 className="signup-page-typo">CONTINUE WITH FACEBOOK</h5>
                </Grid>
              </Grid>
            </LoginSocialFacebook>
            <LoginSocialGoogle
              client_id={process.env.REACT_APP_GG_APP_ID || ""}
              fetch_basic_profile={true}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log("provider:", provider, "data:", data);
                SocialLoginAPI("google", data?.sub, data?.email);
              }}
              onReject={(err) => {
                console.log(err);
              }}
              className="socialmedia-box"
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs: 2, sm: 2, md: 2 }}>
                  <img src={google_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs: 10, sm: 10, md: 10 }}>
                  <h5 className="signup-page-typo">CONTINUE WITH GOOGLE</h5>
                </Grid>
              </Grid>
            </LoginSocialGoogle>
            {/* <LoginSocialInstagram
              client_id={process.env.INSTAGRAM_CLIENT_ID || ""}
              client_secret={process.env.INSTAGRAM_CLIENT_SECRET || ""}
              redirect_uri={"http://localhost:3000/register"}
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log("provider:", provider, "data:", data);
              }}
              onReject={(err: any) => {
                console.log(err);
              }}
              className="socialmedia-box"
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs: 2, sm: 2, md: 2 }}>
                  <img src={instagram_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs: 10, sm: 10, md: 10 }}>
                  <h5 className="signup-page-typo">CONTINUE WITH INSTAGRAM</h5>
                </Grid>
              </Grid>
            </LoginSocialInstagram>

            <LoginSocialTwitter
              client_id={process.env.TWITTER_CLIENT_ID || ""}
              redirect_uri={"http://localhost:3000/register"}
              onLoginStart={onLoginStart}
              onLogoutSuccess={onLogoutSuccess}
              onResolve={({ provider, data }: IResolveParams) => {
                console.log("provider:", provider, "data:", data);
              }}
              onReject={(err: any) => {
                console.log(err);
              }}
              className="socialmedia-box"
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs: 2, sm: 2, md: 2 }}>
                  <img src={twitter_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs: 10, sm: 10, md: 10 }}>
                  <h5 className="signup-page-typo">CONTINUE WITH TWITTER</h5>
                </Grid>
              </Grid>
            </LoginSocialTwitter> */}
            <Box
              className="socialmedia-box"
              onClick={() => navigate("/continue-with-number")}
            >
              <Grid container sx={{ display: "flex", alignItems: "center" }}>
                <Grid size={{ xs: 2, sm: 2, md: 2 }}>
                  <img src={phone_icon} alt="fb-icon" loading="lazy" />
                </Grid>
                <Grid size={{ xs: 10, sm: 10, md: 10 }}>
                  <Grid size={{ xs: 10, sm: 10, md: 10 }}>
                    <h5 className="signup-page-typo">CONTINUE WITH PHONE</h5>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;

import { Box } from "@mui/material";
import arrow_icon from "../assets/arrow_icon.svg";

function TermsOfServices() {
  return (
    <>
      {/* <Box className="custom-margin" sx={{ display: "flex", justifyContent: "center"}}>
      <LogoWhiteDude />
    </Box> */}
      <Box className="custom-margin">
        <Box className="main_top_heading">
          <span className="arrow_icon">
            <img src={arrow_icon} alt="arrow" />
          </span>
          <span className="main_sec_text">Terms of Service</span>
        </Box>
        <Box className="main_sec_content">
          <p>
            Welcome to White Dudes! By using our app, you agree to comply with
            and be bound by the following Terms of Service. Please read them
            carefully.
          </p>
          <div style={{ marginBottom: 10 }}>
            <h3>1. Acceptance of Terms</h3>
            <p>
              By accessing or using White Dudes!, you acknowledge that you have
              read, understood, and agree to be bound by these Terms of Service
              and our Privacy Policy. If you do not agree to these terms, please
              do not use our app.
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>2. Services Provided</h3>
            <p>
              White Dudes offers a platform for online dating and incorporates
              various games (e.g., chess, checkers, word searches, tic-tac-toe)
              to enhance user engagement and entertainment while waiting for
              messages from potential matches.
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>3. User Conduct</h3>
            <p style={{marginBottom: 0}}>
              Users are expected to engage in respectful and courteous behavior
              while using the app. This includes, but is not limited to:
            </p>
            <ul style={{marginLeft: 25}}>
              <li>Treating other users with respect.</li>
              <li>
                Not engaging in any form of harassment, bullying, or
                intimidation.
              </li>
              <li>
                Not using the platform for any unlawful or prohibited
                activities.
              </li>
            </ul>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>4. Game Features</h3>
            <p>
              The games available on White Dudes are intended for entertainment
              purposes. We reserve the right to modify, suspend, or discontinue
              any game or feature at any time without notice.
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>5. User Content</h3>
            <p>
              Users may submit content within the app, including messages and
              game interactions. By submitting content, you grant White Dudes a
              non-exclusive, worldwide, royalty-free license to use, reproduce,
              and display such content.
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>6. Intellectual Property</h3>
            <p>
              All content, trademarks, and other intellectual property
              associated with White Dudes are owned by us or our licensors.
              Users may not reproduce, distribute, or create derivative works
              from our content without express written permission
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>7. Limitation of Liability</h3>
            <p>
              White Dudes is not responsible for any damages arising from the
              use of, or inability to use, the app or its features. We do not
              guarantee that the app will be error-free or uninterrupted.
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>8. Termination</h3>
            <p>
              We reserve the right to terminate or suspend your access to the
              app at our discretion, without notice, for conduct that violates
              these Terms of Service or is harmful to other users or our
              interests
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>9. Changes to Terms</h3>
            <p>
              We may update these Terms of Service from time to time. We will
              notify users of any significant changes by posting a notice in the
              app or sending an email. Your continued use of the app after such
              changes constitutes your acceptance of the new Terms.
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>10. Governing Law</h3>
            <p>
              These Terms of Service shall be governed by and construed in
              accordance with the laws of [Your State/Country].
            </p>
          </div>
          <div style={{ marginBottom: 10 }}>
            <h3>11. Contact Information</h3>
            <p>
              For questions or concerns regarding these Terms, please contact us
              at [contact email].
            </p>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default TermsOfServices;

import { Box, FormControl } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import HomepageCard from "../components/HomepageCard";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import styles from "./moduleCss/continuephonepage.module.css";
import { useNavigate } from "react-router-dom";
import  Grid  from "@mui/material/Grid2";
import { useUserContext } from "../context/user-context";
import useUserDataCheck from "../hooks/check-user-data";

function WhatIntoIt() {
  const navigate = useNavigate();
  const {setUserData} = useUserContext();

  const initialValues: {yourHobby: string} = {
    yourHobby: "",
  };

  const loginValidation = Yup.object().shape({
    yourHobby: Yup.string().required("Your hobby is required"),
  });

  useUserDataCheck(['your_hobby'], '/upload-image')

  const handleWhatIntoIt = (values: {yourHobby: string}) => {
    if (values.yourHobby) {
      setUserData((prev) => ({
        ...prev,
        your_hobby: values.yourHobby
      }))
      navigate("/upload-image");
    }
  };

  return (
    <Grid container>
      {/* Left Banner */}
      <Grid size={{xs: 12, sm:6, md:6}} className="banner_login">
        <HomepageCard />
      </Grid>

      {/* Right Form Section */}
      <Grid size={{xs: 12, sm:6, md:6}} className="login-form">
        <Box className="container_section">
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>

          <Box className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 } }}>
            <h2 className={styles.phone_texthead}>What are you Into?</h2>

            <FormControl sx={{ m: 2, width: "100%" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={loginValidation}
                onSubmit={handleWhatIntoIt}
              >
                <Form>
                  <h4 style={{ marginBlock: "0px", color: "#616161", fontWeight: "normal", textAlign: "center" }}>
                    Select your hobbies
                  </h4>

                  <Field as="select" className="custom-select-dropdown" style={{ marginTop: "5px" }} id="yourHobby" name="yourHobby">
                    <option value="" disabled>
                      Select Preference
                    </option>
                    <option value="Football">Football</option>
                    <option value="Cricket">Cricket</option>
                    <option value="BasketBall">BasketBall</option>
                    <option value="Other">Other</option>
                  </Field>

                  <p style={{ color: "red", fontSize: "14px" }} className="m-2">
                    <ErrorMessage name="yourHobby" />
                  </p>

                  <Grid container spacing={2}>
                    <Grid size={{xs:6}}>
                      <button className="next_button" type="submit">
                        NEXT
                      </button>
                    </Grid>
                    <Grid size={{xs:6}}>
                      <button className="deny_button" style={{ width: "100%" }} onClick={() => navigate(-1)}>
                        BACK
                      </button>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default WhatIntoIt;

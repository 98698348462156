import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

interface UserDataValues {
  full_name: string;
  email: string;
  gender: string;
  dob: string; // You might also consider using Date type
  sex_orientation: string;
  sexual_interest: string;
  relationship_status: string;
  institute_study: string;
  latitude: string;
  longitude: string;
  distance: number;
  state_id: number;
  city_id: number;
  looking_for: string;
  your_hobby: string;
  fcm_token: string;
}

// Define the shape of the context
interface UserContextType {
  userData: UserDataValues;
  userToken: string;
  setUserToken: React.Dispatch<React.SetStateAction<string>>; // Adjusted to string type
  setUserData: React.Dispatch<React.SetStateAction<UserDataValues>>;
}

// Create a default context value
const defaultUserData: UserDataValues = {
  full_name: "",
  email: "",
  gender: "",
  dob: "",
  sex_orientation: "",
  sexual_interest: "",
  relationship_status: "",
  institute_study: "",
  latitude: "",
  longitude: "",
  distance: 0,
  state_id: 0,
  city_id: 0,
  looking_for: "",
  your_hobby: "",
  fcm_token: "",
};


// Create the UserContext with the default value
const UserContext = createContext<UserContextType>({
  userData: defaultUserData,
  setUserData: () => { },
  userToken: "",
  setUserToken: () => { },
});

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [userData, setUserData] = useState<UserDataValues>(() => {
    const savedData = localStorage.getItem("userData");
    return savedData ? JSON.parse(savedData) : defaultUserData;
  });

  const [userToken, setUserToken] = useState<string>('');

  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(userData));
  }, [userData]);

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(userToken));
  }, [userToken]);

  return (
    <UserContext.Provider
      value={{ userData, setUserData, userToken, setUserToken }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUserContext = () => {
  return useContext(UserContext);
};

import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import white_dude_banner from "../assets/white_dude_logo.svg";

const Navbar = () => {
  const location = useLocation();
  const [hideButton, setHideButton] = useState(false);

  useEffect(() => {
    // Update the hideButton state based on the current path
    setHideButton(location.pathname === '/register' || location.pathname === '/');
  }, [location]);

  return (
    <div className="navbar">
      <Link to='/'><img src={white_dude_banner} alt="GeeksforGeeks Logo" loading="lazy" width={100} height={80} /></Link>
     
      {hideButton && (
        <button className="btn btn-primary">
          <Link to="/register" className="link">Create an Account</Link>
        </button>
      )}
    </div>
  );
};

export default Navbar;

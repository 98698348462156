import React, { useState } from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HomepageCard from "../components/HomepageCard";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import styles from "./moduleCss/continuephonepage.module.css";
import Grid from "@mui/material/Grid2";
import like from "../assets/like.png";
import smile from "../assets/smile.png";
import heart from "../assets/heart.png";
import happy from "../assets/happy.png";
import confuse from "../assets/confuse.png";
import inlove from "../assets/inlove.png";
import { useUserContext } from "../context/user-context";
import useUserDataCheck from "../hooks/check-user-data";

interface TLookingForPage {
  id: number;
  image: string;
  looking: string;
  onClick: () => void;
}

const LookingForData = [
  { id: 1, image: like, looking: "Long Term Partner" },
  { id: 2, image: smile, looking: "Long Term but, Short Term Ok" },
  { id: 3, image: heart, looking: "New Friends" },
  { id: 4, image: happy, looking: "Short Term Fun" },
  { id: 5, image: confuse, looking: "Still Figuring it Out" },
  { id: 6, image: inlove, looking: "Short Term but, Long Term Ok" },
];

const LookingForPage: React.FC = () => {
  const navigate = useNavigate();
  const { setUserData} = useUserContext()

  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>("");


  useUserDataCheck(['looking_for'], '/what-intoIt')

  const handleNextButton = () => {
    if (selectedId === null) {
      // If no option is selected, show error message and prevent navigation
      setErrorMessage("Please select an option*");
    } else {
      let lookingFor =
        LookingForData.find((item) => item.id === selectedId)?.looking || "";

        setUserData((prev) => ({
          ...prev,
         looking_for: lookingFor 
        }))
      navigate("/what-intoIt");
    }
  };
  const LookingForOption: React.FC<TLookingForPage> = ({
    id,
    image,
    looking,
    onClick,
  }) => {
    const isSelected = selectedId === id;
    const borderStyle = isSelected ? "1px solid #00f" : "none"; // You can customize the color

    return (
      <Grid
        key={id}
        className="icon_box"
        size={{ xs: 4, sm: 6, md: 6 }}
        onClick={onClick}
      >
        <img
          src={image}
          alt={looking}
          className="icon_img"
          style={{
            cursor: "pointer",
            border: borderStyle,
            transition: "border 0.3s ease",
          }}
        />
        <p>{looking}</p>
      </Grid>
    );
  };

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section lookingforpage">
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>

          <Box
            className="common_card_container input-box"
            sx={{ mt: { xs: 3, md: 5 } }}
          >
            <h2 className={styles.phone_texthead}>You’re Looking For..</h2>

            <Grid className="main_box" container spacing={0}>
              {LookingForData.map((item) => (
                <LookingForOption
                  key={item.id}
                  id={item.id}
                  image={item.image}
                  looking={item.looking}
                  onClick={() => {
                    setSelectedId(item.id); // Set the selected ID
                    setErrorMessage("");
                  }} // Pass id to identify which item is clicked
                />
              ))}
              {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <Box
              className="next_button looking_for_btn"
              sx={{ mb: 1 }}
              onClick={handleNextButton}
            >
              NEXT
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LookingForPage;

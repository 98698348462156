import {  Box, Slider } from "@mui/material";
import HomepageCard from "../components/HomepageCard";
import styles from "./moduleCss/continuephonepage.module.css";
import "react-phone-input-2/lib/high-res.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import  Grid  from "@mui/material/Grid2";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import { useUserContext } from "../context/user-context";
import useUserDataCheck from "../hooks/check-user-data";

function DistanceSlider() {
  const navigate = useNavigate();
  const [preference, setPreference] = useState<any>(0);
  const {setUserData} = useUserContext()

  const handleChangeDistance = (event: Event, newValue: number | number[]) => {
    setPreference(newValue as number);
  };

  const handleStudyInstitute = () => {
    if (preference) {
      setUserData((prev) => ({
        ...prev, 
        distance: preference
      }))
      navigate("/your-location");
    }
  };

  useUserDataCheck(['distance'], '/your-location')

  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
         <Box>
         <LogoWhiteDude />
          </Box>
          <Box  className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}>
            {/* <h2 className={styles.phone_texthead} style={{marginBottom:"3%"}}>Where are you from?</h2> */}
            <h2 className={`${styles.phone_texthead} ${styles.custom_texthead}`}>Where are you from?</h2>

            <p className={`${styles.custom_texthead} ${styles.custom_paratext} `}>
              Use the slider to set the maximum you would like potential matches
              to be located
            </p>
            <Box className={styles.distance_box} sx={{ width: '100%' }}> 
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "15px"}}>
              <h5 className={styles.f_18}>Distance Preference</h5>
              <h5 className={styles.f_18}>{preference} ml</h5>
            </div>

            <Slider
              defaultValue={50}
              aria-label="Default"
              className="distance_slider"
              value={preference}
              onChange={handleChangeDistance}
              valueLabelDisplay="auto"
              sx={{ color: "#EC1B77"}}
            />
            </Box>

            <Grid container spacing={2} sx={{ width: '100%' }}>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box
                  className="next_button"
                  // onClick={() => navigate("/your-location")}
                  onClick={handleStudyInstitute}
                >
                  NEXT
                </Box>
              </Grid>
              <Grid size={{ xs: 6, sm: 6, md: 6 }}>
                <Box className="deny_button" sx={{ width: "100%" }} onClick={() => navigate(-1)}>
                  BACK
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default DistanceSlider;


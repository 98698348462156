import {
  Box,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { GetMethod } from "../api/fetch";
import HomepageCard from "../components/HomepageCard";
import LogoWhiteDude from "../utils/LogoWhiteDude";
import Grid from "@mui/material/Grid2";
import { useUserContext } from "../context/user-context";
import useUserDataCheck from "../hooks/check-user-data";

interface TState {
  state_name: string;
  id: number;
}

interface TCity {
  city_name: string;
  id: number;
}

function LocationPreference() {
  const { setUserData } = useUserContext()
  const [yourState, setYourState] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [cities, setFetchCities] = useState<TCity[]>([]);
  const [states, setFetchStates] = useState<TState[]>([]);
  const [stateId, setStateId] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);
  const [loadingStates, setLoadingStates] = useState<boolean>(true);
  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [stateError, setStateError] = useState<string | null>(null);
  const [cityError, setCityError] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchStates = async () => {
      setLoadingStates(true);
      try {
        const res: any = await GetMethod("getConstants");
        setFetchStates(res?.data?.data?.STATES);
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        setLoadingStates(false);
      }
    };
    fetchStates();
  }, []);

  useEffect(() => {
    const fetchCities = async () => {
      if (stateId) {
        setLoadingCities(true);
        try {
          const res: any = await GetMethod(`getCities?state_id=${stateId}`);
          setFetchCities(res?.data?.data);
        } catch (error) {
          console.error("Error fetching cities:", error);
        } finally {
          setLoadingCities(false);
        }
      }
    };
    fetchCities();
  }, [stateId]);

  const handleStateChange = (event: { target: { value: string } }) => {
    const selectedState = event.target.value as string;
    setYourState(selectedState);
    setStateError(null);
    const stateObj = states.find((state) => state.state_name === selectedState);
    if (stateObj) {
      setStateId(stateObj.id);
      setCity(""); // Reset city when state changes
    }
  };

  const handleCityChange = (event: { target: { value: string } }) => {
    const selectedCity = event.target.value as string;
    setCity(selectedCity);
    setCityError(null);
    const cityObj = cities.find((city) => city.city_name === selectedCity);
    if (cityObj) {
      setCityId(cityObj.id)
    }
  };

  const validate = () => {
    let valid = true;
    if (!yourState) {
      setStateError("Please select your state.");
      valid = false;
    } else {
      setStateError(null);
    }

    if (!city) {
      setCityError("Please select your city.");
      valid = false;
    } else {
      setCityError(null);
    }

    return valid;
  };

  const handleSave = () => {
    if (validate()) {
      if (cityId && stateId) {
        setUserData((prev) => ({
          ...prev, 
          state_id: stateId,
          city_id: cityId
        }))
      }
      navigate("/looking-for");
    }
  };


  useUserDataCheck(['state_id', 'city_id'], '/looking-for')


  return (
    <Grid container>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="banner_login">
        <HomepageCard />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 6 }} className="login-form">
        <Box className="container_section">
          <Box className="login-section">
            <LogoWhiteDude />
          </Box>
          <Box
            className="common_card_container input-box" sx={{ mt: { xs: 3, md: 5 }}}
          >
            <FormControl sx={{ width: "100%" }} error={Boolean(stateError)}>
              <Select
                value={yourState}
                onChange={handleStateChange}
                displayEmpty
                inputProps={{ "aria-label": "Select Your State" }}
                sx={{
                  width: "100%",
                  fontFamily: '"Josefin Sans", sans-serif', 
                  "&.Mui-focused": {
                      "& .MuiOutlinedInput-notchedOutline": {
                          border: "1px solid #cacaca",
                      }
                  },
                  mt: 2,
              }}
              >
                <MenuItem value="" disabled>
                  Select Your State
                </MenuItem>
                {loadingStates ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  states.map((item) => (
                    <MenuItem key={item.id} value={item.state_name}>
                      {item.state_name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {stateError && <FormHelperText>{stateError}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }} error={Boolean(cityError)}>
              <Select
                value={city}
                onChange={handleCityChange}
                displayEmpty
                inputProps={{ "aria-label": "Select Your City" }}
                sx={{
                  width: "100%",
                  "&.Mui-focused": {
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "1px solid #cacaca",
                    },
                  },
                  mt: 2,
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                <MenuItem value="" disabled>
                  Select Your City
                </MenuItem>
                {loadingCities ? (
                  <MenuItem disabled>
                    <CircularProgress size={24} />
                  </MenuItem>
                ) : (
                  cities.map((item) => (
                    <MenuItem key={item.id} value={item.city_name}>
                      {item.city_name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {cityError && <FormHelperText>{cityError}</FormHelperText>}
            </FormControl>

            <Grid container spacing={2} sx={{ width: "100%", mt: 2 }}>
              <Grid size={{ xs: 6 }}>
                <Box
                  sx={{
                    cursor: yourState && city ? "pointer" : "not-allowed",
                    opacity: yourState && city ? 1 : 0.5,
                  }}
                  className="next_button"
                  onClick={handleSave}
                >
                  NEXT
                </Box>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <Box className="deny_button" onClick={() => navigate(-1)}>
                  BACK
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LocationPreference;

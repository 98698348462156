import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../context/user-context"; // Adjust the path accordingly

const useUserDataCheck = (
  requiredFields: (keyof typeof userData)[],
  redirectPath: string
) => {
  const { userData } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    const allFieldsPresent = requiredFields.every((field) => userData[field]);

    if (allFieldsPresent) {
      navigate(redirectPath);
    }
  }, [navigate, userData, requiredFields, redirectPath]);
};

export default useUserDataCheck;
